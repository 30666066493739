
import React from 'react';
import Container from 'react-bootstrap/Container';
import parse from 'html-react-parser'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";

// styles
import styles from "./SellNFT.module.scss";

const SellNFT = (props) => {
        return (
            <div className={`section ${styles.sellNFTSec}`}>
                <Container>
                    <div className={styles.createNFTSlider}>
                        <Swiper
                            dir={props.currLocale === "ar" ? "rtl" : "ltr"}
                            navigation={{
                                nextEl: '.slide-button-next',
                                prevEl: '.slide-button-prev',
                            }}
                            modules={[Navigation, Pagination]}
                            slidesPerView={1}
                            slidesPerGroup={1}
                            freeMode={true}
                            mousewheel={true}
                            grabCursor={true}
                            speed={400}
                            autoHeight={true}
                            direction={"vertical"}
                            pagination={{
                                clickable: true,
                            }}
                        >
                            {props?.faqData?.faqsData?.length > 0 && props.faqData.faqsData.map((info, index) =>
                                <SwiperSlide key={index}>
                                    <div className={styles.createNft}>
                                        <h2 className={`h3 mb-3 w-100 ${styles.title}`}>{info.question && info.question}</h2>
                                        <div className={styles.desc}>{info.answer && parse(info.answer)}</div>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        
                        <div className="slide-button-next"></div>
                        <div className="slide-button-prev"></div>
                    </div>
                </Container>
            </div>
        )
}
export default SellNFT;
